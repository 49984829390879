import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { signinform } from '../../formsConfig/formsConfig';
import { Button, Input, Modal, Loader, Flex, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import { MoreOauths } from '../Oauths';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { getSlug } from '../../utils/utils';
import { connect, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';
import styled from 'styled-components';
import { remember, showAuthForm } from '../../store/actions/auth';

const Btns = styled.div`
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  label {
    color: ${({ theme }) => theme.colore_testo_box} !important;
  }
`;
const CustomButton = styled(Button)`
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SigninForm = props => {
  const {
    inModal,
    error,
    clearError,
    signinInit,
    loading,
    redirect,
    whereToGo,
    oauths,
    oauthInfo,
    isOauth,
    oauthSendToken,
    visible,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const inputRef = useRef();
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  const signInForm = signinform(t('auth.email'), t('auth.password'), inputRef, inModal);
  t();
  const dispatch = useDispatch();
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    if (error) {
      setShowModal(true);
      dispatch(showAuthForm(true));
    }

    // return () => {};
  }, [error]);

  const login = () => {
    localStorage.setItem('exp', formData.recordami.value);
    signinInit(formData.email.value, formData.password.value).then(() => {
      error ? dispatch(showAuthForm(true)) : dispatch(showAuthForm(false));
      dispatch(actionCreators.getViewer());
    });
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit } = useForm(
    login,
    signInForm,
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        {...inp}
      />
    );
  });

  let oauthsContent = null;
  if (oauths !== undefined) {
    if (oauths.length > 0) {
      oauthsContent = (
        <MoreOauths
          oauths={oauths}
          oauthInfo={oauthInfo}
          inModal={inModal}
          whereToGo={whereToGo}
          isOauth={isOauth}
          oauthSendToken={oauthSendToken}
          lastLocation={lastLocation}
          redirect={redirect}
        />
      );
    }
  }

  if (redirect) {
    let to = whereToGo;
    if (lastLocation !== null) {
      if (
        lastLocation.pathname.split('/')[1] === 'activate' ||
        lastLocation.pathname.split('/')[1] === 'reset-password' ||
        lastLocation.pathname.split('/')[1] === 'auth'
      ) {
        to = '/';
      } else {
        to = lastLocation.pathname;
      }
    }
    if (inModal) {
      to = `/${getSlug()}`;
    }
    return <Redirect to={to} />;
  } else {
    return (
      <Container>
        <form onSubmit={formSubmitHandler}>
          <AuthContentWrapper title='Benvenuto. Accedi con le tue credenziali'>
            {inputs}
            <Flex justify='space-between' style={{ marginTop: '-20px' }}>
              <Button
                white
                secondary
                upper
                bold
                type='submit'
                loading={loading}
                width='48%'
                style={{ justifyContent: 'center' }}
              >
                Accedi
              </Button>
              {visible && (
                <CustomButton active upper bold type='button' width='48%'>
                  <Link to='/auth/register'>Registrati</Link>
                </CustomButton>
              )}
            </Flex>
          </AuthContentWrapper>
        </form>

        <Flex>
          <Text style={{ marginRight: 5 }} text_box>
            {t('auth.forgotpswlabel')}
          </Text>
          <Link to='/auth/recovery-password'>
            <Text bold style={{ textDecoration: 'underline' }} text_link>
              {t('auth.presshere')}
            </Text>
          </Link>
        </Flex>

        <Modal alert show={showModal} close={modalHandler} type='error'>
          <ErrorInModal error={error} />
        </Modal>
      </Container>
    );
  }
};

SigninForm.defaultProps = {
  inModal: false,
};

SigninForm.propTypes = {
  inModal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.number,
  redirect: PropTypes.bool,
  whereToGo: PropTypes.string,
  clearError: PropTypes.func,
  signinInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    redirect: state.auth.redirect,
    error: state.auth.errorsignin,
    loading: state.auth.loadingsignin,
    oauths: state.app.config.oauths,
    visible: state.app.config.visible,
    oauthInfo: state.app.oauthInfo,
    isOauth: state.auth.isOauth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signinInit: (email, password, recordami) =>
      dispatch(actionCreators.signinInit(email, password, recordami)),
    clearError: () => dispatch(actionCreators.signinClearError()),
    oauthSendToken: (code, connect, data) =>
      dispatch(actionCreators.oauthSendToken(code, connect, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
